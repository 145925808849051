.page {
    display: block;

    flex-direction: column;
    flex: 1;

    max-width: 1024px;
    min-width: 386px;
    width: -webkit-fill-available;
    align-self: center;

    z-index: 200;

    @supports not (width: -webkit-fill-available) {
        width: 100%;
    }

    &__wrapper {
        display: flex;
        position: absolute;
        flex-direction: column;
        overflow: hidden;
        left: 0.5rem;
        right: 0.5rem;
        top: 0.5rem;
        bottom: 0.5rem;
        @supports not (
            (-webkit-backdrop-filter: blur(5px)) or (backdrop-filter: blur(5px))
        ) {
            border-radius: 1rem;
            background-color: rgba($color: #333333, $alpha: 0.8);
            &::after {
                position: absolute;
                width: 100%;
                height: 100%;
            }
        }
    }
    &__controls {
        display: flex;
        position: absolute;
        right: 0;
        justify-content: flex-end;
    }

    &__control-icon {
        width: 3rem;
        height: 3rem;
        padding: 0 16px 0 0;
        white-space: nowrap;
        background-repeat: no-repeat;
        background-position: center;
        cursor: pointer;

        &--close {
            background-image: url(../../assets/fi-xtluxl-times-thin.svg);
        }
    }

    &__background {
        display: flex;
        position: absolute;

        width: 100%;
        height: 100%;

        border-radius: 1rem;
        z-index: -1;
        @supports (
            (-webkit-backdrop-filter: blur(5px)) or (backdrop-filter: blur(5px))
        ) {
            background-color: rgba(0, 0, 0, 0.1);
            box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.8);
            backdrop-filter: blur(5px);
        }
        @supports not (
            (-webkit-backdrop-filter: blur(5px)) or (backdrop-filter: blur(5px))
        ) {
            background-color: #ffffff;
            // filter: blur(1px);
        }

        @media screen and (min-width: 768px) {
            background-color: rgba(255, 255, 255, 0.1);
        }
    }

    &__content {
        display: block;

        color: #fff;
        padding: 1rem;

        overflow-y: auto;

        &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        }
        &::-webkit-scrollbar {
            width: 0.5rem;
        }
        &::-webkit-scrollbar-track-piece:start {
            margin-top: 2px;
        }
        &::-webkit-scrollbar-track-piece:end {
            margin-bottom: 2px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #ffffff;
            border-radius: 0rem 1rem 1rem 0;
        }
        @media screen and (min-width: 768px) {
            padding: 2rem;
        }
    }
    @media screen and (min-width: 768px) {
        position: relative;
    }
}
