.app-header {
    display: flex;
    flex-direction: column;

    margin-bottom: 1rem;

    &__name {
        // display: flex;
        text-align: center;
    }

    &__titles {
        display: block;
        text-align: center;
    }

    &__title {
    }

    &__title-spacer {
        margin: 0 0rem;
        &::after {
            content: ', ';
        }

        @media screen and (min-width: 455px) {
            margin: 0 2rem;
            &::after {
                content: '❖';
            }
        }
    }
}
