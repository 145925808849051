.frame {
    display: flex;
    flex-flow: column;
    width: calc(100vw - 3rem);
    height: calc(100vh - 3rem);

    padding: 0.5rem;

    border: 2px solid white;
    border-radius: 1rem;
}
