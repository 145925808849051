.parallax {
    width: 100%;
    height: 100%;

    &__wrapper {
        position: absolute;
        
        left: 50%;

        transform: translateX(-50%);
    }

    &__moving-element {
        position: relative;
        display: block;
        // margin: 0.5rem 0 ;
    }
}
